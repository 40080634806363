/*eslint-disable*/
import React, { useContext, useState, useEffect } from "react";
import Table from "../../components/Table";
import { AuthContext } from "../../services/context";
import * as utils from "../../services/utilities";
import * as config from "../../services/config";
import DashboardLayout from "../../components/DashboardLayout";
import { DialogTitle, DialogContent, Box, Typography } from "@mui/material";
import Button from "../../components/Button";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import orderOpenIcon from "../../assets/OrderNew.svg";
import availableIcon from "../../assets/AvailableTransactions.svg";
import claimedIcon from "../../assets/ClaimTransactions.svg";
import totalIcon from "../../assets/TotalTransactionIcon.svg";
import MUIDialog from "../../components/Modal";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    gap: "50px",
    marginBottom: "25px",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  containerBoxWrapper: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ddd",
    width: "300px",
    borderRadius: "10px",
    padding: "2px",
    background: " #fff",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  containerBox: {
    display: "flex",
    // justifyContent: "center",
    padding: "12px 12px",
    alignItems: "center",
    gap: "15px",
  },
  tokenIcon: {
    height: "55px",
    width: "55px",
    background: "#ECF4FF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
  },
}));

const columns = [
  { Header: "Order Id", accessor: "orderId" },
  {
    Header: "Order Date",
    accessor: "orderDate",
    Cell: ({ row }) =>
      utils.formatDate(
        row.original.orderDate,
        "ISO",
        config.defaults.DATE_DISPLAY_FORMAT
      ),
  },
  { Header: "Description", accessor: "description" },
  { Header: "Code", accessor: "code" },
  {
    Header: "Amount",
    accessor: "amount",
    align: "right",
    Cell: ({ row }) =>
      row.original.amount
        ? new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(row.original.amount)
        : "N/A",
  },
  { Header: "Ordered By", accessor: "orderedBy" },
];

export const Transactions = () => {
  const { planService, surveyService, settings, employee } =
    useContext(AuthContext);
  const [transactionHistory, setTransactionHistory] = useState(null);
  /*eslint-disable-next-line*/
  const [isLoading, setisLoading] = useState(true);
  const [openTokenPlan, setOpenTokenPlan] = useState(false);
  const [balanceData, setBalanceData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const paymentProcessorEnabled = settings.find(
    (s) => s.key === "IsPaymentProcessorEnabled"
  )?.value;
  const isPaymentEnabled =
    paymentProcessorEnabled === "true"
      ? employee?.subscription
        ? true
        : false
      : true;

  const fetchTransationsHistory = async () => {
    setisLoading(true);
    try {
      const response = await planService.getTransactions();
      setTransactionHistory(response);
    } catch (error) {
      // utils.log.error("Error fetching transactions", error);
    } finally {
      setisLoading(false);
    }
  };
  const fetchBalance = async () => {
    setisLoading(true);
    try {
      const response = await surveyService.getBalance();
      setBalanceData(response);
    } catch (error) {
      // utils.log.error("Error in fetching Balance in Transactions", error);
    } finally {
      setisLoading(false);
    }
  };

  const fetchOrder = async () => {
    setisLoading(true);
    try {
      const response = await surveyService.getOrders();
      setOrderData(response);
    } catch (error) {
      // utils.log.error("Error in fetch Order", error);
    } finally {
      setisLoading(false);
    }
  };
  useEffect(() => {
    fetchTransationsHistory();
    fetchBalance();
    fetchOrder();
  }, []);

  const helpOutLineButtons = (
    <div>
      <Button
        size="medium"
        variant="contained-primary"
        style={{
          borderRadius: "4px",
          background: !isPaymentEnabled ? "#aaa" : "",
          opacity: !isPaymentEnabled ? "0.8" : "",
        }}
        disabled={!isPaymentEnabled}
        onClick={() => setOpenTokenPlan(true)}
      >
        Purchase Token
      </Button>
    </div>
  );

  const classes = useStyles();
  return (
    <>
      <DashboardLayout
        title="Survey Response Tokens"
        description="Survey Response Tokens are claimed when a recipient either completes a survey or exempts themselves from it."
        code={"surevy-response-token"}
      >
        <div className={` ${classes.container} onboarding-19 `}>
          <div className={classes.containerBoxWrapper}>
            <div className={classes.containerBox}>
              <div className={classes.tokenIcon}>
                <img
                  style={{ height: "35px", borderRadius: "10px" }}
                  src={orderOpenIcon}
                />
              </div>
              <div style={{ paddingTop: "3px" }}>
                <div style={{ fontSize: "14px" }}>Orders(Open)</div>
                <div style={{ fontSize: "30px", fontWeight: 600 }}>
                  {orderData?.length ?? 0}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.containerBoxWrapper}>
            <div className={classes.containerBox}>
              <div className={classes.tokenIcon}>
                <img
                  style={{ height: "35px", borderRadius: "10px" }}
                  src={totalIcon}
                />
              </div>
              <div style={{ paddingTop: "3px" }}>
                <div style={{ fontSize: "14px" }}>Total</div>
                <div style={{ fontSize: "30px", fontWeight: 600 }}>
                  {balanceData?.total ?? 0}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.containerBoxWrapper}>
            <div className={classes.containerBox}>
              <div className={classes.tokenIcon}>
                <img
                  style={{ height: "35px", borderRadius: "10px" }}
                  src={claimedIcon}
                />
              </div>
              <div style={{ paddingTop: "3px" }}>
                <div style={{ fontSize: "14px" }}>Claimed</div>
                <div style={{ fontSize: "30px", fontWeight: 600 }}>
                  {balanceData?.claimed !== undefined &&
                  balanceData?.pctClaimed !== undefined
                    ? `${balanceData.claimed}(${balanceData.pctClaimed}%)`
                    : "0(0%)"}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.containerBoxWrapper}>
            <div className={classes.containerBox}>
              <div className={classes.tokenIcon}>
                <img
                  style={{ height: "35px", borderRadius: "10px" }}
                  src={availableIcon}
                />
              </div>
              <div style={{ paddingTop: "3px" }}>
                <div style={{ fontSize: "14px" }}>Available</div>
                <div style={{ fontSize: "30px", fontWeight: 600 }}>
                  {" "}
                  {balanceData?.available !== undefined &&
                  balanceData?.pctAvailable !== undefined
                    ? `${balanceData.available}(${balanceData.pctAvailable}%)`
                    : "0(0%)"}
                </div>
              </div>
            </div>
          </div>
          {/* <div className={classes.containerBoxWrapper}>
            <p>Total</p>
            <p>{balanceData?.total ?? 0}</p>
          </div>
          <div className={classes.containerBoxWrapper}>
            <p>Claimed</p>
            <p>
              {balanceData?.claimed !== undefined &&
              balanceData?.pctClaimed !== undefined
                ? `${balanceData.claimed}(${balanceData.pctClaimed}%)`
                : "0(0%)"}
            </p>
          </div>
          <div className={classes.containerBoxWrapper}>
            <p>Available</p>
            <p>
              {balanceData?.available !== undefined &&
              balanceData?.pctAvailable !== undefined
                ? `${balanceData.available}(${balanceData.pctAvailable}%)`
                : "0(0%)"}
            </p>
          </div> */}
        </div>
      </DashboardLayout>

      <DashboardLayout
        title="Transaction History"
        description="View transactions for the whole organization below."
        code={"transactions"}
        helpOutLineButtons={helpOutLineButtons}
      >
        <Table
          columns={columns}
          data={transactionHistory}
          filterTourClassName={"onboarding-20"}
          paginationTourClassName={"onboarding-21"}
        />
        {openTokenPlan && (
          <SurveyResponseToken
            openTokenPlan={openTokenPlan}
            setOpenTokenPlan={setOpenTokenPlan}
          />
        )}
      </DashboardLayout>
    </>
  );
};

const SurveyResponseToken = ({ openTokenPlan, setOpenTokenPlan }) => {
  utils.assert(openTokenPlan, "openTokenPlan is required");
  utils.assert(setOpenTokenPlan, "setOpenTokenPlan is required");

  const { surveyService, employee } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const [lookUpValue, setLookUpValue] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [surveyTokenPlans, setSurveyTokenplans] = useState(null);
  const [isPaymentLinkFetched, setIsPaymentLinkFetched] = useState(false);

  const fetchSurveyTokenPlans = async () => {
    setIsLoading(true);
    try {
      const response = await surveyService.getPriceList();
      if (response) {
        setSurveyTokenplans(response);
      }
    } catch (error) {
      // utils.log.error(":: Error in fetchSurveyTokenPlans", error);
    } finally {
      setIsLoading(false);
    }
  };

  const hasRolePrimaryCoordinator = employee?.roles?.some(
    (item) => item?.toLowerCase() === "primarycoordinator"
  );

  const columns = [
    {
      Header: "Quantity",
      accessor: "maxQuantity",
      align: "center",
      Cell: (cell) => {
        return cell.value ? cell.value.toLocaleString() : "N/A";
      },
    },
    {
      Header: "Unit/Price",
      accessor: "unitPrice",
      align: "center",
      Cell: ({ cell }) =>
        cell.value
          ? new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(cell.value)
          : "N/A",
    },
    {
      Header: "Discount(%)",
      accessor: "discountPct",
      align: "center",
      Cell: ({ cell }) =>
        cell.value
          ? new Intl.NumberFormat("en-US", {
              style: "percent",
              currency: "USD",
            }).format(cell.value)
          : "N/A",
    },
    {
      Header: "Total price",
      accessor: "totalPrice",
      align: "center",
      Cell: ({ cell }) =>
        cell.value
          ? new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(cell.value)
          : "N/A",
    },
    {
      Header: "Action",
      accessor: "lookupKey",
      align: "center",
      Cell: ({ row }) => {
        const isRowLoading = lookUpValue === row?.values?.lookupKey;
        return (
          <Button
            variant="outlined"
            color="secondary"
            size="medium"
            onClick={() => handleBuy(row)}
            disabled={
              lookUpValue ? true : !hasRolePrimaryCoordinator ? true : false
            }
          >
            {isRowLoading ? "Loading..." : "Buy"}
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    fetchSurveyTokenPlans();
  }, []);

  const handleBuy = async (row) => {
    setLookUpValue(row.original.lookupKey);
    try {
      const { values } = row;
      const { maxQuantity } = values;
      const payload = {
        productCode: "SurveyResponseToken",
        quantity: maxQuantity,
        ReturnUrl: window.location.origin,
      };

      const response = await surveyService.purchase(payload);
      if (response) {
        window.open(response?.redirect, "_blank");
        setIsPaymentLinkFetched(true);
      }
    } catch (error) {
      // utils.log.error(":: Error in handleBuy()");
      enqueueSnackbar(error?.message, { variant: "errror" });
    } finally {
      setLookUpValue(null);
    }
  };

  return (
    <MUIDialog
      open={Boolean(openTokenPlan)}
      onClose={() => setOpenTokenPlan(false)}
      fullWidth
      maxWidth="lg"
      isfullScreen={false}
      PaperProps={{ style: { height: "800px" } }}
    >
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h5>Survey Completed Tokens</h5>
        </Box>
      </DialogTitle>
      <DialogContent>
        {isPaymentLinkFetched ? (
          <Box>
            <hr style={{ margin: "0px" }} />
            <Typography
              variant="h6"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "50px",
              }}
            >
              Purchasing in progress....
            </Typography>
          </Box>
        ) : (
          <Table
            columns={columns}
            data={surveyTokenPlans}
            isLoading={isLoading}
            hideFilter
            hidePagination
          />
        )}
      </DialogContent>
    </MUIDialog>
  );
};
