import Button from "../Button";
import AddIcon from "@mui/icons-material/Add";
import { Tooltip } from "@mui/material";
// import useMediaQuery from "../../hooks/useMediaQuery";
import Debug from "../../services/debug";

export const HeaderActions = ({
  buttonTitle = "Add",
  onAdd,
  disabled = false,
  tooltipTitle = "",
  tourClassName,
  debug,
  debugValue,
  buttonIds = "",
  ...rest
}) => {
  // const { isMdDown, isSmDown } = useMediaQuery();
  return (
    <div className={tourClassName} id={buttonIds}>
      <Tooltip title={tooltipTitle} arrow>
        <span>
          {debug && (
            <span style={{ marginRight: "10px" }}>
              <Debug value={debugValue} />
            </span>
          )}
          <Button
            size="medium"
            onClick={onAdd}
            // startIcon={!isSmDown && <AddIcon />}
            startIcon={<AddIcon />}
            variant="contained-primary"
            style={{
              borderRadius: "4px",
              background: disabled ? "#aaa" : "",
              opacity: disabled ? "0.8" : "",
            }}
            disabled={disabled}
            tool
          >
            {/* {isSmDown ? <AddIcon /> : isMdDown ? "Add" : buttonTitle} */}
            {buttonTitle}
          </Button>
        </span>
      </Tooltip>
    </div>
  );
};
