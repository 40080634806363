import NotPublishIcon from "../../assets/NotPublish.png";
import serverDownIcon from "../../assets/MaintenanceIssue.png";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Typography } from "antd";
import Button from "../../components/Button";
import Input from "../../components/Input/inputs";
import * as utils from "../../services/utilities";
import RecipientService from "../../services/recipient";
import { useContext } from "react";
import { AuthContext } from "../../services/context";

const initialLoadingState = {
  exemption: false,
  surveyStart: false,
};

const useStyles = makeStyles({
  card: {
    background: "#fff",
    width: "auto",
    minWidth: "450px",
    maxWidth: "600px",
    margin: "80px auto",
    borderRadius: "12px",
    border: "1px solid #d7d7d7",
    overflow: "hidden",
  },
  surveyError: {
    textAlign: "center",
    padding: "30px 40px 40px 40px",
  },
  content: {
    padding: "30px 40px 40px 40px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  footer: {
    background: "#f8f8f8",
    borderTop: "1px solid #d7d7d7",
    padding: "30px 40px 40px 40px",
  },
});

const Landing = ({ handleSurveyStart, survey, setSurvey, surveyCode }) => {
  // utils.log.component("Landing");

  // #region Assertions
  utils.assert(handleSurveyStart, "handleSurveyGet required");
  // #endregion

  // utils.log.info(":: survey in Landing",survey)

  // #region Initialize
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { employeeService, settings, employee } = useContext(AuthContext);
  // const surveyCode = utils.getQuerystring("code");

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(initialLoadingState);
  const [uid, setUid] = useState(utils.getQuerystring("uid"));
  const [accessCode, setAccessCode] = useState(
    utils.getQuerystring("accessCode")
  );
  // utils.log.debug(`uid: ${uid}, accessCode: ${accessCode}`);
  const [serverDownModalOpen, setServerDownModalOpen] = useState(false);
  const recipientService = new RecipientService(null, uid, accessCode);

  const paymentProcessorEnabled = settings?.find(
    (s) => s.key === "IsPaymentProcessorEnabled"
  )?.value;

  const isPaymentEnabled =
    paymentProcessorEnabled === "true"
      ? employee?.subscription
        ? true
        : false
      : true;
  // #endregion

  const getSurvey = async () => {
    try {
      const response = await recipientService.getSurvey(
        uid,
        accessCode,
        surveyCode
      );
      if (response) {
        setSurvey(response);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      // utils.log.error(":: Error in getSurvey", error);
      enqueueSnackbar(
        "Survey is not published.  Please contact the administrator.",
        { variant: "error" }
      );
      return false;
    } finally {
      setLoading(initialLoadingState);
    }
  };

  const handleExemptionClick = async () => {
    try {
      setLoading({
        ...initialLoadingState,
        exemption: true,
      });
      const isSurveySuccess = await getSurvey();
      if (isSurveySuccess) {
        setOpen(true);
      }
    } catch (error) {
      // utils.log.error(":: Error in handleExemptionClick", error);
    }
  };

  const handleSurveyClick = async () => {
    try {
      setLoading({
        ...initialLoadingState,
        surveyStart: true,
      });
      const isSurveySuccess = await getSurvey();
      if (isSurveySuccess) {
        handleSurveyStart(surveyCode, uid, accessCode);
      }
    } catch (error) {
      // utils.log.error(":: Error in handleSurveyClick()", error);
    }
  };

  const getServerStatusCode = async () => {
    try {
      const response = await employeeService.getServerStatus();
      const responseData = await response.json();
      const buildInfo = responseData.find((item) => item.key === "Code:Build");
      if (!buildInfo) {
        setServerDownModalOpen(true);
      }
    } catch (error) {
      // utils.log.error("Landing.getServerStatusCode()",error);
    }
  };

  useEffect(() => {
    getServerStatusCode();
  }, []);

  if (survey && !survey?.isEnabled) {
    return (
      <Modal
        link={NotPublishIcon}
        title={"Survey Not Live"}
        description={
          "This survey is not yet live.Please try again later or contact your system administrator"
        }
      />
    );
  }

  if (serverDownModalOpen) {
    return (
      <Modal
        link={serverDownIcon}
        title={"System Maintenance"}
        description={
          "Our systems are currently under maintenance. Please try again after an hour or two."
        }
      />
    );
  }

  const handleExemptionClose = () => setOpen(false);
  // #endregion

  return (
    <div className={classes.card}>
      {!surveyCode ? (
        <div className={classes.surveyError}>
          <p>
            The survey details do not seem to be correct, and you cannot proceed
            further. If you think this is a mistake, please contact your
            organization.
          </p>
        </div>
      ) : (
        <>
          <div className={classes.content}>
            <p style={{ marginBottom: "10px" }}>
              To initiate the survey, you need to provide your UID & Access Code
              that has been shared with you over the mail by your organisation.
            </p>

            <Input
              label="UID"
              fullWidth
              required
              name="uid"
              placeholder={"Please write your UID..."}
              onChange={(e) => setUid(e?.target?.value)}
              value={uid}
            />

            <Input
              label="Access Code"
              required
              fullWidth
              placeholder={"Please Enter your Acccess Code..."}
              name="accessCode"
              onChange={(e) => setAccessCode(e?.target?.value)}
              value={accessCode}
            />

            <div>
              <Button
                onClick={handleSurveyClick}
                variant="contained"
                color="primary"
                size="large"
                disabled={
                  !isPaymentEnabled ||
                  !accessCode ||
                  !uid ||
                  loading.surveyStart
                }
              >
                {loading.surveyStart ? "Loading..." : "Start survey"}
              </Button>
            </div>
          </div>

          <div className={classes.footer}>
            <p style={{ marginBottom: "20px" }}>
              Don't want to fill the survey?
            </p>
            <p>
              You can mark yourself as exempted from the survey by using the
              below button.
            </p>

            <Button
              variant="outlined"
              color="secondary"
              size="large"
              onClick={handleExemptionClick}
              disabled={!surveyCode || !uid || !accessCode || loading.exemption}
            >
              {loading.exemption ? "Loading..." : "Exempt Survey"}
            </Button>
          </div>

          {survey && (
            <ExemptionModal
              uid={uid}
              accessCode={accessCode}
              open={open}
              survey={survey}
              formData={{ accessCode, uid }}
              handleClose={handleExemptionClose}
            />
          )}
        </>
      )}
    </div>
  );
};

export function ExemptionModal({
  uid,
  accessCode,
  survey,
  handleClose,
  open,
  formData,
}) {
  // utils.log.api(
  //   `landing.ExemptionModal(uid: ${uid}, accessCode: ${accessCode}, survey: ${survey?.code})`
  // );
  // #region Assertions
  utils.assert(uid != null, "uid required");
  utils.assert(accessCode != null, "accessCode required");
  utils.assert(survey != null, "Survey required.");
  utils.assert(handleClose != null, "handleClose required.");
  utils.assert(open != null, "open required.");
  utils.assert(formData != null, "formData required.");
  // #endregion
  // #region Functions
  const fetchExemptions = async () => {
    //const response = await surveyService.getAllExemptions();
    const result = await recipientService.getExemptions(uid, accessCode);
    //const result = await response.json();
    setExemptions(result);
  };
  // #endregion
  // #region Initialize
  const [reason, setReason] = useState("");
  //const { surveyService } = useContext(AuthContext);
  const recipientService = new RecipientService(null, uid, accessCode);
  const [exemptions, setExemptions] = useState([]);
  // #endregion
  // #region Events
  const handleChange = (event) => {
    setReason(event.target.value);
  };
  useEffect(() => {
    fetchExemptions();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestBody = {
      exemptionCode: reason,
      surveyCode: survey.code,
      recipient: formData.uid,
      accessCode: formData.accessCode,
    };

    try {
      await recipientService.recipientExemption(requestBody);
      handleClose();
      console.info("Exempted successfully!");
    } catch (error) {
      console.error("Failed to exempt recipient", { error });
    }
  };
  // #endregion

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="md"
      className="customizedDialog"
    >
      <DialogTitle
        sx={{ m: 0, p: 2, textAlign: "center" }}
        id="customized-dialog-title"
      >
        Recipient Exemption
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <form onSubmit={handleSubmit}>
          <Typography
            variant="h6"
            marginBottom={2}
            color="#041433"
            style={{ fontFamily: "Public-sans" }}
          >
            Survey: {survey?.name || "N/A"}
          </Typography>
          <FormControl sx={{ marginBottom: 5 }} fullWidth>
            <InputLabel id="demo-simple-select-label">Reason</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={reason}
              label="Age"
              onChange={handleChange}
            >
              {exemptions?.map((exemption) => (
                <MenuItem value={exemption?.id}>
                  {exemption?.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ textAlign: "right" }}>
            <Button type="submit" size="large">
              Submit
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}
export default Landing;

const Modal = ({ link, title, description }) => {
  return (
    <div
      style={{
        background: "#fff",
        width: "auto",
        minWidth: "450px",
        maxWidth: "600px",
        margin: "80px auto",
        borderRadius: "12px",
        border: "1px solid #d7d7d7",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          padding: "20px 0px 100px 0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img height={"300px"} width={"300px"} src={link} alt="not-publish" />
        <h4 style={{ textAlign: "center" }}>{title}</h4>
        <p
          style={{ fontSize: "19px", padding: "0px 60px", textAlign: "center" }}
        >
          {description}
        </p>
      </div>
    </div>
  );
};
